const CommonTranslations = {
    customerTypes: {
        en: ['Individuals', 'Companies'],
        et: ['Eraisikud', 'Ettevõtted'],
        ru: ['Частные лица', 'Компании']
    },
    // clientTypes: {
    //     en: ['All', 'Individuals', 'Companies'],
    //     et: ['Kõik', 'Eraisikud', 'Ettevõtted'],
    //     ru: ['Все', 'Частные лица', 'Компании']
    // }
}

export default CommonTranslations